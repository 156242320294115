import { template as template_f0b03449aed045bd82c76bb79705e3c6 } from "@ember/template-compiler";
const SidebarSectionMessage = template_f0b03449aed045bd82c76bb79705e3c6(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
