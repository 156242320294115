import { template as template_68afa0396b8a4ff397a40647fc0d71c3 } from "@ember/template-compiler";
const FKLabel = template_68afa0396b8a4ff397a40647fc0d71c3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
