import { template as template_8706fc04cb1243e486836a8f8fdb9829 } from "@ember/template-compiler";
const FKControlMenuContainer = template_8706fc04cb1243e486836a8f8fdb9829(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
